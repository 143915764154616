<script setup lang="ts">
const { t } = useT();
const { open, close } = useAppModals();
const { data: appInitData } = useAppInitData();
const { isMobile } = useDevice();
const { seasonInitData } = useSeasonsData({ immediate: false });
const loginGuard = useLoginGuard();

const errorMsgs = {
	notValid: t("Form not valid"),
	notCompleted: t("Must be completed")
};

const pageLoading = ref(true);

const formData = computed(
	() =>
		appInitData.value?.registrationFormData || {
			emailEnabled: true,
			phoneEnabled: true,
			facebookAuthEnabled: true,
			googleAuthEnabled: true,
			contactsOrder: ["email", "phone"]
		}
);

const { buttons: enabledButtons } = useAuthorizationSocialButtons(formData);

const {
	email,
	password,
	isShowPassword,
	signupLoading,
	acceptTerms,
	acceptRestrictedStates,
	error,
	errorMessage,
	isAcceptTermsError,
	isAcceptRestrictedStatesError,
	isButtonEnabled,
	isEmailShowError,
	isEmailTouched,
	isPasswordShowError,
	isPasswordTouched,
	handleSubmit,
	handleInput
} = useSignup(errorMsgs, open, close);

onMounted(() => {
	pageLoading.value = false;
});

const openSeasonPopup = () => {
	loginGuard({
		success: () => {
			if (seasonInitData.value?.isEnabled && !seasonInitData.value?.isActive) {
				open("LazyOModalSeasonWelcome", { isWelcome: true });
			}
		}
	});
};

const handleSignup = async () => {
	await handleSubmit();
	openSeasonPopup();
};
</script>

<template>
	<MAuthorizationSocialButtons v-if="isMobile" :socials="enabledButtons" entryPoint="registration" />
	<form autocomplete="off" class="form" @submit.prevent="handleSignup">
		<div class="labels">
			<label class="row-input">
				<AAnimationSlide
					:active="isEmailShowError"
					:distance="5"
					:duration="0.2"
					iterationCount="3"
					mode="left-to-right"
				>
					<AText class="label" variant="tempe">{{ t("Email") }}</AText>
				</AAnimationSlide>
				<MEmailInput
					v-model="email"
					:invalid="isEmailShowError"
					autocomplete="username"
					clear
					class="field"
					location-tid="home-email"
					:disabled="pageLoading"
					:placeholder="t('Enter your Email')"
					@blur="isEmailTouched = true"
					@input="handleInput('email')"
				/>
				<AAnimationSlide v-if="isEmailShowError" :active="isEmailShowError">
					<AText class="invalid" variant="topeka" data-tid="home-email-validation">{{
						errorMessage || t("Please provide a valid email")
					}}</AText>
				</AAnimationSlide>
			</label>
			<label v-if="isShowPassword" class="row-input">
				<AAnimationSlide
					:active="isPasswordShowError"
					:distance="5"
					:duration="0.2"
					iterationCount="3"
					mode="left-to-right"
				>
					<AText class="label" variant="tempe">{{ t("Password") }}</AText>
				</AAnimationSlide>
				<MPasswordInput
					v-model="password"
					:invalid="isPasswordShowError"
					autocomplete="current-password"
					:placeholder="t('Enter your Password')"
					is-svg
					class="field"
					:disabled="pageLoading"
					location-tid="home-password"
					@blur="isPasswordTouched = true"
					@input="handleInput('password')"
				/>
				<AAnimationSlide v-if="isPasswordShowError" :active="isPasswordShowError">
					<AText class="invalid" variant="topeka" data-tid="home-password-validation">{{
						password.length ? t("Password must be at least 6 characters long") : t("Please create a password")
					}}</AText>
				</AAnimationSlide>
			</label>
		</div>

		<div class="row-checkbox">
			<MCheckbox
				v-model="acceptRestrictedStates"
				data-tid="home-age-confirm-chk"
				no-animation
				:disabled="pageLoading"
				:invalid="isAcceptRestrictedStatesError"
				@input="handleInput('acceptRestrictedStates')"
			>
				<AText as="div" class="checkbox-text" :class="{ disabled: pageLoading }">
					<i18n-t keypath="I am at least 18 years old and I am not a resident of the {key1}.">
						<template #key1>
							<NuxtLink class="link" target="_blank" to="/page/agreement/#restricted" data-tid="home-age-rules-link">
								<AText :modifiers="['link']">{{ t("Restricted states") }}</AText></NuxtLink
							>
						</template>
					</i18n-t>
				</AText>
			</MCheckbox>
			<MCheckbox
				v-model="acceptTerms"
				:disabled="pageLoading"
				no-animation
				:invalid="isAcceptTermsError"
				data-tid="home-terms-confirm-chk"
				@input="handleInput('acceptTerms')"
			>
				<AText as="div" class="checkbox-text" :class="{ disabled: pageLoading }">
					<i18n-t
						keypath="I accept the Funrize the {key1} and {key2} (including, for the California residents the {key3}."
					>
						<template #key1>
							<NuxtLink class="link" target="_blank" to="page/agreement" data-tid="home-agreement-rules-link">
								<AText :modifiers="['link']">{{ t("Terms of Use") }}</AText></NuxtLink
							>
						</template>
						<template #key2>
							<NuxtLink class="link" target="_blank" to="page/privacy" data-tid="home-privacy-rules-link"
								><AText :modifiers="['link']">{{ t("Privacy Policy") }}</AText></NuxtLink
							>
						</template>
						<template #key3>
							<NuxtLink
								class="link"
								target="_blank"
								to="page/california-privacy-notice"
								data-tid="home-california-rules-link"
							>
								<AText :modifiers="['link']">{{ t("California Privacy Notice") }}</AText></NuxtLink
							>
						</template>
					</i18n-t>
				</AText>
			</MCheckbox>
			<AAnimationSlide v-if="error === 'agree'" :active="error === 'agree'" class="checkbox-error">
				<AText class="invalid" variant="topeka" data-tid="home-rules-disagree-error">{{
					t("Must be completed")
				}}</AText>
			</AAnimationSlide>
		</div>
		<div class="buttons">
			<AButton
				:modifiers="[!isButtonEnabled ? 'disabled' : '']"
				type="submit"
				variant="primary"
				size="xl"
				class="submit"
				data-tid="home-create-account-btn"
			>
				<AAnimationSpin v-if="signupLoading || pageLoading">
					<ASvg class="spinner" name="loading/spinner" />
				</AAnimationSpin>
				<AText v-else :modifiers="['medium']" variant="toledo">{{ t("Join now") }}</AText>
			</AButton>
		</div>
	</form>
	<MAuthorizationSocialButtons
		:socials="enabledButtons"
		location-tid="home"
		entryPoint="registration"
		is-small
		class="socials-mob"
	/>
</template>

<style scoped lang="scss">
.flex-column {
	display: flex;
	flex-direction: column;
}

.form {
	@extend .flex-column;
	gap: gutter(2);

	@include media-breakpoint-up(md) {
		gap: gutter(3);
	}
}

.labels {
	@extend .flex-column;
	gap: gutter(2);

	@include media-breakpoint-up(md) {
		flex-direction: row;
	}
}

.spinner {
	font-size: 28px;
}

.submit {
	width: 100%;

	@include media-breakpoint-up(md) {
		min-width: 328px;
	}
}

.row-input {
	@extend .flex-column;
	gap: gutter(0.5);
}

.row-checkbox {
	@extend .flex-column;
	gap: gutter(0.5);

	@include media-breakpoint-down(md) {
		gap: gutter(1);
	}
}

.invalid {
	color: var(--copenhagen);
}

.buttons {
	position: relative;
	width: 100%;
	display: flex;
	gap: gutter(4);

	@include media-breakpoint-up(md) {
		width: 328px;
	}
}

.socials-mob {
	position: absolute;
	bottom: 0;
	left: 360px;
	display: flex;

	@include media-breakpoint-down(md) {
		display: none;
	}
}

.checkbox-text {
	line-height: 20px;

	&.disabled {
		opacity: 0.5;
	}
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
</style>
